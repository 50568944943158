import { Component } from "react";
import { withTranslation } from "react-i18next";
import { CircularProgress, Button, IconButton } from "@mui/material";
// cmp
import PlayerChooser from "./PlayerChooser";
import PanTiltSlider from "./PanTiltSlider";
import Svg from "../svg";
import Toast from "../Toast";
// services
import Glient from "../../services/glient";
import DeviceType from "../../services/device-type";
import Devices from "../../services/devices";
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { decimal2Hex } from "../../services/utils";
import { icons } from "@local/theme";
// types
import type { CSSProperties } from "react";
import type { ValueOf } from "type-fest";
import type { TFunction } from "i18next";
import type { WithTranslation } from "react-i18next";
import type { DeviceObj, EpDevice } from "../../types/device";
import type { HandlerId } from "../../types/roc-ws";
import type { CmdSendActionCmd, MsgBroadcastAttributeReport } from "../../types/message";
import type { CmdId } from "../../types/misc";

type Props = Readonly<WithTranslation & {
	epDevice: EpDevice;
	streamingCapabilities: number;
	countdownStartTime: number;
	style: CSSProperties; // optional
}>;

type State = {
	streamingUrl: string | null;
	streamingLoading: boolean;
	streamingError: boolean;
	countdown: number;
	currentPan: number | null;
	currentTilt: number | null;
	maxPan: number | null;
	minPan: number | null;
	maxTilt: number | null;
	minTilt: number | null;
	movementType: ValueOf<typeof Constants.MovementTypes> | undefined;
	showHomeBtn: boolean | null;
	showGenericErrorMsg: number | undefined;
};

class StreamingPlayer extends Component<Props, State> {

	#t: TFunction = this.props.t;
	#MAX_RETRIES = 5;

	#intervalId: number | undefined = undefined;
	#enableSliderUpdating = true;
	#retry = 0;
	#glientHandlerId: HandlerId | undefined = undefined;

	public static defaultProps = {
		style: {},
	} satisfies Partial<Props>;

	constructor(props: Props) {
		super(props);

		const clusterC38B = props.epDevice.getClusterByCapAndClusterId(DeviceType.DC38B.cap, DeviceType.DC38B.clusterId);

		this.state = {
			streamingUrl: null,
			streamingLoading: true,
			streamingError: false,
			countdown: props.countdownStartTime,
			currentPan: clusterC38B ? Number(clusterC38B[ClusterConstants.DC38B.Attributes.CurrentPan]) : null,
			currentTilt: clusterC38B ? Number(clusterC38B[ClusterConstants.DC38B.Attributes.CurrentTilt]) : null,
			maxPan: clusterC38B ? Number(clusterC38B[ClusterConstants.DC38B.Attributes.MaxPan]) : null,
			minPan: clusterC38B ? Number(clusterC38B[ClusterConstants.DC38B.Attributes.MinPan]) : null,
			maxTilt: clusterC38B ? Number(clusterC38B[ClusterConstants.DC38B.Attributes.MaxTilt]) : null,
			minTilt: clusterC38B ? Number(clusterC38B[ClusterConstants.DC38B.Attributes.MinTilt]) : null,
			movementType: clusterC38B?.[ClusterConstants.DC38B.Attributes.MovementType],
			showHomeBtn: clusterC38B ? (clusterC38B.hasOwnProperty(ClusterConstants.DC38B.Attributes.GoHomeCapability) ? clusterC38B[ClusterConstants.DC38B.Attributes.GoHomeCapability] ?? null : true) : null,
			showGenericErrorMsg: undefined,
		};

		this.handleDeviceAttributeReport = this.handleDeviceAttributeReport.bind(this);
		this.handleGetStreamURL = this.handleGetStreamURL.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		this.handleStreamingLoad = this.handleStreamingLoad.bind(this);
		this.handleStreamingError = this.handleStreamingError.bind(this);
		this.handlePanAbsoluteChange = this.handlePanAbsoluteChange.bind(this);
		this.handleTiltAbsoluteChange = this.handleTiltAbsoluteChange.bind(this);
		this.handlePanAbsoluteChangeCommitted = this.handlePanAbsoluteChangeCommitted.bind(this);
		this.handleTiltAbsoluteChangeCommitted = this.handleTiltAbsoluteChangeCommitted.bind(this);
		this.handleBackToHomeClick = this.handleBackToHomeClick.bind(this);
		this.handlePanMaxClick = this.handlePanMaxClick.bind(this);
		this.handlePanMinClick = this.handlePanMinClick.bind(this);
		this.handleTiltMaxClick = this.handleTiltMaxClick.bind(this);
		this.handleTiltMinClick = this.handleTiltMinClick.bind(this);
	}

	override componentDidMount() {
		Devices.on("attributeReport", this.handleDeviceAttributeReport);

		this.getStreamUrl();
	}

	override componentWillUnmount() {
		Devices.off("attributeReport", this.handleDeviceAttributeReport);

		window.clearInterval(this.#intervalId);

		if (this.#glientHandlerId) {
			Glient.abort(this.#glientHandlerId);
		}
	}

	handleDeviceAttributeReport(msg: MsgBroadcastAttributeReport, device: DeviceObj) {
		if (msg.payload.deviceId === this.props.epDevice.id && msg.payload.cluster_id === DeviceType.DC38B.clusterId) {
			const cluster = device.getClusterByEpIdAndCapAndClusterId(this.props.epDevice.epId, DeviceType.DC38B.cap, DeviceType.DC38B.clusterId);
			if (this.#enableSliderUpdating) {
				this.setState({
					currentPan: cluster ? Number(cluster[ClusterConstants.DC38B.Attributes.CurrentPan]) : null,
					currentTilt: cluster ? Number(cluster[ClusterConstants.DC38B.Attributes.CurrentTilt]) : null,
				});
			}
			this.setState({
				maxPan: cluster ? Number(cluster[ClusterConstants.DC38B.Attributes.MaxPan]) : null,
				minPan: cluster ? Number(cluster[ClusterConstants.DC38B.Attributes.MinPan]) : null,
				maxTilt: cluster ? Number(cluster[ClusterConstants.DC38B.Attributes.MaxTilt]) : null,
				minTilt: cluster ? Number(cluster[ClusterConstants.DC38B.Attributes.MinTilt]) : null,
				movementType: cluster?.[ClusterConstants.DC38B.Attributes.MovementType],
				showHomeBtn: cluster ? (cluster.hasOwnProperty(ClusterConstants.DC38B.Attributes.GoHomeCapability) ? cluster[ClusterConstants.DC38B.Attributes.GoHomeCapability] : true) : null,
			});
		}
	}

	getStreamUrl() {
		const cmdId = Devices.getCmdId(this.props.streamingCapabilities);
		if (cmdId === null) {
			console.warn("cmdId is null", this.props.streamingCapabilities);
			this.setState({
				streamingError: true,
			});
		} else {
			this.#glientHandlerId = Devices.getStreamUrl(this.props.epDevice, DeviceType.DC38A, cmdId, this.handleGetStreamURL);
		}
	}

	handleGetStreamURL(error: Error | null, url: string | undefined) {
		this.#glientHandlerId = undefined;
		if (!error && url) {
			this.setState({
				streamingUrl: url,
				streamingError: false,
			});
			this.startCountdown();
		} else {
			this.setState({
				streamingUrl: null,
				streamingError: true,
			});
		}
	}

	startCountdown() {
		this.#intervalId = window.setInterval(() => {
			if (this.state.countdown > 0) {
				this.setState((prevState) => {
					const countdown = prevState.countdown - 1;
					if (prevState.streamingUrl !== null && prevState.streamingLoading && countdown === 0) {
						return {
							streamingLoading: false,
							streamingError: true,
							countdown: countdown,
						};
					} else {
						return {
							countdown: countdown,
						};
					}
				});
			} else {
				window.clearInterval(this.#intervalId);
			}
		}, 1000);
	}

	resetCountdown() {
		window.clearInterval(this.#intervalId);

		if (this.#glientHandlerId) {
			Glient.abort(this.#glientHandlerId);
		}

		this.setState({
			streamingUrl: null,
			streamingLoading: true,
			streamingError: false,
			countdown: this.props.countdownStartTime,
		});

		this.getStreamUrl();
	}

	handleResetClick() {
		this.resetCountdown();
	}

	handleStreamingLoad() {
		this.setState({
			streamingLoading: false,
			streamingError: false,
		});
	}

	handleStreamingError() {
		if (this.#retry < this.#MAX_RETRIES) {
			this.#retry += 1;
			this.resetCountdown();
		} else {
			this.#retry = 0;
			window.clearInterval(this.#intervalId);
			this.setState({
				streamingLoading: false,
				streamingError: true,
			});
		}
	}

	handlePanAbsoluteChange(value: number) {
		this.#enableSliderUpdating = false;
		this.setState({
			currentPan: value,
		});
	}

	handleTiltAbsoluteChange(value: number) {
		this.#enableSliderUpdating = false;
		this.setState({
			currentTilt: value,
		});
	}

	handlePanAbsoluteChangeCommitted(value: number) {
		this.sendCmd(ClusterConstants.DC38B.CmdIds.PanAbsolute, value);
	}

	handleTiltAbsoluteChangeCommitted(value: number) {
		this.sendCmd(ClusterConstants.DC38B.CmdIds.TiltAbsolute, value);
	}

	handleBackToHomeClick() {
		this.sendCmd(ClusterConstants.DC38B.CmdIds.GoHome);
	}

	handlePanMaxClick() {
		this.sendCmd(ClusterConstants.DC38B.CmdIds.PanRelative, this.state.maxPan);
	}

	handlePanMinClick() {
		this.sendCmd(ClusterConstants.DC38B.CmdIds.PanRelative, this.state.minPan);
	}

	handleTiltMaxClick() {
		this.sendCmd(ClusterConstants.DC38B.CmdIds.TiltRelative, this.state.maxTilt);
	}

	handleTiltMinClick() {
		this.sendCmd(ClusterConstants.DC38B.CmdIds.TiltRelative, this.state.minTilt);
	}

	sendCmd(cmdId: CmdId, value: number | null | undefined = undefined) {
		const cmd: CmdSendActionCmd = {
			action: "sendActionCmd",
			gatewayId: this.props.epDevice.gwId,
			srcGw: this.props.epDevice.srcGw,
			deviceId: this.props.epDevice.id,
			endpoint: this.props.epDevice.epId,
			caps: DeviceType.DC38B.cap,
			clusterId: DeviceType.DC38B.clusterId,
			cmdId: cmdId,
		};
		if (typeof value === "number") {
			cmd.value = decimal2Hex(value, 4);
		}
		Glient.send(cmd, (error, msg) => {
			this.#enableSliderUpdating = true;
			if (!error && msg?.payload.status === "ok") {
				this.setState({
					showGenericErrorMsg: undefined,
				});
			} else {
				this.setState({
					showGenericErrorMsg: Date.now(),
				});
			}
		});
	}

	showErrorMessage() {
		return (
			<>
				{this.#t("media.playbackError")}
				<br />
				<br />
				<Button id="btn-streaming-try-again" variant="contained" onClick={this.handleResetClick}>{this.#t("media.tryAgain")}</Button>
			</>
		);
	}

	showContent() {
		return (
			<>
				{this.state.streamingLoading ? <CircularProgress /> : StreamingPlayer.getCountdownText(this.#t, this.state.countdown)}
				{(this.state.streamingUrl === null) ? null : (this.state.countdown === 0)
					? <Button id="btn-streaming-continue" variant="contained" onClick={this.handleResetClick}>{this.#t("media.continue")}</Button>
					: this.getPlayerBuildup(this.state.streamingUrl)
				}
			</>
		);
	}

	static getCountdownText(t: TFunction, countdown: number) {
		if (countdown === 0) {
			return null;
		}

		return (
			<div style={{ marginBottom: "16px" }}>
				{t("media.countdown", { count: countdown })}
			</div>
		);
	}

	getPlayerBuildup(streamingUrl: string) {
		if ([this.state.maxPan, this.state.minPan, this.state.maxTilt, this.state.minTilt].every((value) => (typeof value === "number"))) {
			if (this.state.movementType === Constants.MovementTypes.Absolute && [this.state.currentPan, this.state.currentTilt].every((value) => (typeof value === "number"))) {
				return this.getAbsoluteControls(streamingUrl);
			}
			if (this.state.movementType === Constants.MovementTypes.Relative) {
				return this.getRelativeControls(streamingUrl);
			}
		}

		return (
			<PlayerChooser
				capabilities={this.props.streamingCapabilities}
				url={streamingUrl}
				onLoad={this.handleStreamingLoad}
				onError={this.handleStreamingError}
			/>
		);
	}

	getAbsoluteControls(streamingUrl: string) {
		return (
			<div style={{ position: "relative" }}>
				<PlayerChooser
					capabilities={this.props.streamingCapabilities}
					url={streamingUrl}
					onLoad={this.handleStreamingLoad}
					onError={this.handleStreamingError}
				/>
				{!this.state.streamingLoading &&
					<>
						<PanTiltSlider
							orientation="horizontal"
							min={this.state.minPan}
							max={this.state.maxPan}
							value={this.state.currentPan}
							onChange={this.handlePanAbsoluteChange}
							onChangeCommitted={this.handlePanAbsoluteChangeCommitted}
						/>
						<PanTiltSlider
							orientation="vertical"
							min={this.state.minTilt}
							max={this.state.maxTilt}
							value={this.state.currentTilt}
							onChange={this.handleTiltAbsoluteChange}
							onChangeCommitted={this.handleTiltAbsoluteChangeCommitted}
						/>
						{this.state.showHomeBtn &&
							<div style={{ position: "absolute", right: "8px", bottom: "8px", backgroundColor: "rgba(0, 0, 0, 0.4)", borderRadius: "50%" }}>
								<IconButton id="btn-streaming-back2home" sx={{ padding: "10px", width: "44px", height: "44px", color: "common.white" }} onClick={this.handleBackToHomeClick}>
									<icons.Overscan />
								</IconButton>
							</div>
						}
					</>
				}
			</div>
		);
	}

	getRelativeControls(streamingUrl: string) {
		return (
			<div style={{ position: "relative" }}>
				<PlayerChooser
					capabilities={this.props.streamingCapabilities}
					url={streamingUrl}
					onLoad={this.handleStreamingLoad}
					onError={this.handleStreamingError}
				/>
				{!this.state.streamingLoading &&
					<>
						<div style={{ position: "absolute", top: "8px", left: "calc(50% - 20px)", backgroundColor: "rgba(0, 0, 0, 0.4)", borderRadius: "50%" }}>
							<IconButton id="btn-streaming-tilt-max" sx={{ padding: 0, width: "44px", height: "44px", color: "common.white" }} onClick={this.handleTiltMaxClick}>
								<icons.ExpandLess />
							</IconButton>
						</div>
						<div style={{ position: "absolute", left: "8px", top: "calc(50% - 20px)", backgroundColor: "rgba(0, 0, 0, 0.4)", borderRadius: "50%" }}>
							<IconButton id="btn-streaming-tilt-min" sx={{ padding: 0, width: "44px", height: "44px", color: "common.white" }} onClick={this.handlePanMinClick}>
								<icons.ChevronLeft />
							</IconButton>
						</div>
						<div style={{ position: "absolute", right: "8px", top: "calc(50% - 20px)", backgroundColor: "rgba(0, 0, 0, 0.4)", borderRadius: "50%" }}>
							<IconButton id="btn-streaming-pan-max" sx={{ padding: 0, width: "44px", height: "44px", color: "common.white" }} onClick={this.handlePanMaxClick}>
								<icons.ChevronRight />
							</IconButton>
						</div>
						<div style={{ position: "absolute", bottom: "8px", left: "calc(50% - 20px)", backgroundColor: "rgba(0, 0, 0, 0.4)", borderRadius: "50%" }}>
							<IconButton id="btn-streaming-pan-min" sx={{ padding: 0, width: "44px", height: "44px", color: "common.white" }} onClick={this.handleTiltMinClick}>
								<Svg src="navigation/arrowDropDown.svg" />
							</IconButton>
						</div>
						{this.state.showHomeBtn &&
							<div style={{ position: "absolute", right: "8px", bottom: "8px", backgroundColor: "rgba(0, 0, 0, 0.4)", borderRadius: "50%" }}>
								<IconButton id="btn-streaming-back2home" sx={{ padding: "10px", width: "44px", height: "44px", color: "common.white" }} onClick={this.handleBackToHomeClick}>
									<icons.Overscan />
								</IconButton>
							</div>
						}
					</>
				}
			</div>
		);
	}

	closeGenericErrorToast() {
		this.setState({
			showGenericErrorMsg: undefined,
		});
	}

	override render() {
		return (
			<>
				<div style={{ textAlign: "center", minHeight: "320px", ...this.props.style }}>
					{this.state.streamingError ? this.showErrorMessage() : this.showContent()}
				</div>
				<Toast
					autoHideDuration={6000}
					severity="error"
					open={this.state.showGenericErrorMsg}
					onClose={this.closeGenericErrorToast}
					message={this.#t("toast.genericErrorMsg")}
				/>
			</>
		);
	}

}

export default withTranslation()(StreamingPlayer);
